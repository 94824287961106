import springer from "springer";
import { isMobile } from "../libs/devices";
import { throttle } from "../libs/throttle";

export function gridImages() {
  const gridImages = document.querySelectorAll(".grid-images");
  if (!gridImages) return;

  gridImages.forEach((grid) => {
    grid.style.transform = isMobile()
      ? "translateX(10%) translateY(-10%) rotate(-30deg)"
      : "translateX(5%) translateY(-20%) rotate(-30deg)";
    const images = grid.querySelectorAll("img");
    const imagesArray = Array.from(images);

    const gridWrapper = grid.parentNode;
    gridWrapper.classList.add("grid-images---wrapper");

    const getPos = gridWrapper.getBoundingClientRect();
    const getPosTop = getPos.top;
    const winHeight = window.innerHeight;
    // throttle he scroll event
    // Scroll event handler
    function handleScroll() {
      const scrollPos = window.scrollY;
      const scrollPosTop = scrollPos + winHeight * 3;

      if (scrollPosTop > getPosTop) {
        imagesArray.forEach((image) => {
          const img = new Image();
          img.src = image.src;
          img.onload = () => {
            image.setAttribute("data-loaded", true);
          };
        });
        gridWrapper.classList.add("grid-images---wrapper--loaded");
        window.removeEventListener("scroll", throttledScroll);
      }
    }
    // Throttled scroll event
    const throttledScroll = throttle(handleScroll, 500); // Adjust the limit (100 ms) as needed

    // Add event listener
    window.addEventListener("scroll", throttledScroll);

    // scrollTrigger Grid
    const tl = gsap.timeline({
      scrollTrigger: {
        trigger: gridWrapper,
        start: "top bottom",
        end: "bottom top",
        scrub: true,
        preventOverlaps: true,
        fastScrollEnd: true,
        onanimationstart: () => {
          gridWrapper.style.pointerEvents = "none";
        },
      },
    });
    const tlNoScrub = gsap.timeline({
      scrollTrigger: {
        trigger: ".grid-images---wrapper",
        start: "center bottom",
        end: "bottom center",
      },
    });

    // images
    imagesArray.forEach((image, index) => {
      let distance = index === 0 ? 125 : 100;
      distance = index === 1 ? 75 : distance;
      distance = index === 2 ? 150 : distance;
      distance = index === 3 ? 100 : distance;

      tl.add(
        gsap.fromTo(
          image,
          {
            y: `-${distance}px`,
            opacity: 0,
            scale: 0.9,
            force3D: true,
            willChange: "transform, opacity",
          },
          {
            y: `${distance}px`,
            opacity: 1.5,
            scale: 1,
            force3D: true,
          }
        ),
        0
      );
    });

    // card
    const springBubble = springer(0.3, 0.7);
    const block = gridWrapper.querySelector(".wrapper---cta");
    tlNoScrub.add(
      gsap.fromTo(
        block,
        {
          scale: 0.8,
          opacity: 0,
        },
        {
          y: "0",
          scale: 1,
          opacity: 1,
          ease: springBubble,
          duration: 2,
          force3D: true,
        }
      ),
      0.25
    );
    tlNoScrub.add(
      gsap.fromTo(
        [
          block.querySelector(".primary-cta"),
          block.querySelector(".h-30px---serif"),
          block.querySelector(".body---book"),
        ],
        {
          scale: 0.8,
          opacity: 0,
        },
        {
          y: "0",
          scale: 1,
          opacity: 1,
          force3D: true,
          ease: springBubble,
          duration: 1,
          stagger: 0.1,
        }
      ),
      0.5
    );
  });
}
