import { EasingFunctions } from "../libs/easings.js";
import { throttle } from "../libs/throttle.js";

export class Marquee {
  constructor(element, options = {}) {
    this.element = element;
    this.duration = options.duration || 10;
    this.direction = options.direction || "left";
    this.gap = options.gap || 20;
    this.clones = options.clones || 8;
    this.masterTimeline = null;
    this.isMouseHover = false;
    this.stopOnHover = options.stopOnHover || false;
    this.init();
  }

  init() {
    const elementWidth = this.getElementWidth();
    const width = this.calculateWidth(elementWidth);
    this.masterTimeline = this.createMasterTimeline(width);

    this.cloneDivs(this.clones);
    this.setupScrollTrigger(this.masterTimeline);
    if (this.stopOnHover && window.innerWidth >= 991) {
      this.hoverDuration = 1000;
      if (this.isTouchDevice()) {
        this.element.addEventListener("touchstart", this.onTouchStart);
      } else {
        this.element.addEventListener("mouseenter", this.onEnter);
        this.element.addEventListener("mouseleave", this.onLeave);
      }
    }
    // activate when in viewport
    window.addEventListener(
      "scroll",
      throttle((e) => {
        if (!this.isMouseHover) this.activateMarquee();
      }, 100)
    );
    this.activateMarquee();
  }

  activateMarquee() {
    // if (ScrollTrigger.isInViewport(this.element)) {
    //   this.masterTimeline.play();
    // } else {
    //   this.masterTimeline.pause();
    // }
    this.masterTimeline.play();
  }

  isTouchDevice() {
    return (
      "ontouchstart" in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    );
  }

  onTouchStart = () => {
    if (!this.isMouseHover) {
      this.onEnter();
    } else {
      this.onLeave();
    }
  };

  onLeave = (e) => {
    this.hoverDuration = 50;
    this.masterTimeline.timeScale(1);
    this.masterTimeline.play();
    this.isMouseHover = false;
  };

  onEnter = () => {
    this.hoverDuration = 1000;
    this.isMouseHover = true;
    let startTime = null,
      startValue = this.masterTimeline.timeScale(),
      endValue = 0;

    const fadeIn = (currentTime) => {
      if (startTime === null) {
        startTime = currentTime;
      }
      const elapsedTime = currentTime - startTime;
      const easingValue = EasingFunctions.easeInOutQuint(
        elapsedTime / this.hoverDuration
      );
      const currentValue = startValue + (endValue - startValue) * easingValue;
      this.masterTimeline.timeScale(currentValue);

      if (elapsedTime < this.hoverDuration) requestAnimationFrame(fadeIn);
      else {
        this.masterTimeline.pause();
        if (!this.isMouseHover) {
          this.masterTimeline.timeScale(1);
          this.masterTimeline.play();
        }
      }
    };
    requestAnimationFrame(fadeIn);
  };

  getElementWidth() {
    return this.element.querySelector("div").getBoundingClientRect().width;
  }

  calculateWidth(elementWidth) {
    return (this.clones - 1) * (elementWidth + this.gap);
  }

  createMasterTimeline(width) {
    const masterTimeline = gsap.timeline();
    const xOffset = this.direction === "left" ? -width : width;
    gsap.set(this.element, {
      gridColumnGap: this.gap,
      display: "flex",
    });
    masterTimeline.add(
      gsap.to(this.element, {
        duration: this.duration,
        willChange: "transform",
        repeat: -1,
        x: `${xOffset}px`,
        ease: "none",
      })
    );

    return masterTimeline;
  }

  cloneDivs(count) {
    for (let i = 0; i < count; i++) {
      const clone = this.element.querySelector("div").cloneNode(true);
      this.element.appendChild(clone);
    }
  }

  setupScrollTrigger(masterTimeline) {
    const timeScaleClamp = gsap.utils.clamp(1, 3);

    ScrollTrigger.create({
      start: 0,
      end: "max",
      onUpdate: (self) => {
        if (!this.isTouchDevice()) {
          const velocity = Math.abs(self.getVelocity() / 400);
          masterTimeline.timeScale(timeScaleClamp(velocity));
          this.updateTween(masterTimeline);
        }
      },
    });
  }

  updateTween(masterTimeline) {
    const tween = gsap.to(masterTimeline, {
      duration: 1.5,
      timeScale: 1,
      paused: true,
    });

    tween.invalidate().restart();
  }
}
